import { numberWithCommas } from "@/utilities/number-formatter";
import "./device.css";

export interface Price {
  price: number;
  storageRom: string;
  storageRam: string;
}

type PhoneCardProps = {
  _id?: string;
  business?: string;
  product?: any;
  prices: Array<{ storageRam: string; storageRom: string; price: number }>;
  handleClick?: any;
};
const PhoneCard = ({ product, prices, handleClick }: PhoneCardProps) => {
  const condition = product ? product.condition.toLowerCase() : "brand new";
  function comparePrice(prices: Price[]): number {
    const result = prices.reduce((prev: Price, curr: Price): Price => {
      return prev.price < curr.price ? prev : curr;
    }, [] as any);
  
    return result.price;
  }
  
  function RamStorage(devicePrices: Array<Price>) {
    const deviceStorage = devicePrices?.reduce((acc: string[], curr) => {
      return acc.concat(curr.storageRom);
    }, []);
    return deviceStorage?.join("/");
  }

  return (
    <div className="phone-card">
      <div style={{ maxWidth: "3.5rem" }}>
        <img
          alt={"img-6"}
          src={product.image[0]}
          width={"100%"}
          height={"100%"}
        />
      </div>

      <div className="p-card-text">
        <h3>{product && product.name}</h3>
        <p>{RamStorage(prices && prices)}</p>
        <p className="p-condition">{condition}</p>

          <h3 style={{textAlign:'center'}} >
            {`₦${numberWithCommas(comparePrice(prices && prices))}`}
          </h3>
      </div>

      {/* <Button
        style={{
          backgroundColor: "var(--keza-brown)",
         border:'none',
          minWidth: "2rem",
          height: "2rem",
        }}
        type="primary"
        shape="round"
        onClick={handleClick}
      >
       View
      </Button> */}
    </div>
  );
};

export default PhoneCard;
