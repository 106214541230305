import { Button, Card, Popover, Row, Table, Col, Input } from 'antd';
import filter from '../../assets/filter.svg';
import { useMemo } from 'react';
//import { Link } from 'react-router-dom';
import { getTag } from '../../utilities/sanitizer';
import { SearchOutlined } from '@ant-design/icons';
import { UserType } from '@/types/user';
import { UserSection } from '@/enums/users';
import { useRecoilState } from 'recoil';
import { CurrentCustomerState } from '@/store/customer';
import { useDownloadApplication } from '@/api/mutations/applications';
import { useDownloadCustomers } from '@/api/mutations/user';

type AllUsersProps = {
  users: Array<Partial<UserType>>;
  loading: boolean;
  setSection: (data: UserSection) => void;
};

const AllUsers = ({ users, loading, setSection }: AllUsersProps) => {
  const [customer, setCustomer] = useRecoilState(CurrentCustomerState);
  const { downloadCustomers, result } = useDownloadCustomers();
  const handleView = (id: string, data: any) => {
    setCustomer({
      ...customer,
      ...data,
      _id: id,
    });
    setSection(UserSection.USER_DETAILS);
  };
  const columns = useMemo(
    () => [
      {
        title: 'Full name',
        dataIndex: 'user',
        key: 'firstName',
        render: (record: any, data: any) => (
          <p className="table-name">
            {data?.user?.firstName} {data?.user?.lastName}
          </p>
        ),
      },

      {
        title: 'Email address',
        dataIndex: 'email',
        key: 'email',
        render: (record: any, data: any) => (
          <p  style={{textTransform:"lowercase",fontSize:"var(--keza-font-16)"}}>{data?.user?.email}</p>
        ),
      },
      {
        title: 'Phone number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        render: (record: any, data: any) => (
          <p className="table-name">{data?.user?.phone_number}</p>
        ),
      },
      {
        title: 'Profile status',
        dataIndex: 'isApproved',
        key: 'isApproved',
        render: (record: any, data: any) => {
          return (
            <>{getTag(data?.user?.isVerified ? 'verified' : 'Unverified')}</>
          );
        },
      },
      {
        title: 'Applications',
        dataIndex: 'applications',
        key: 'applications',
        render: (record: any, data: any) => {
          return <>{getTag(data?.application ? 'Active' : 'Inactive')}</>;
        },
      },
      {
        title: 'Actions',
        dataIndex: '_id',

        key: '_id',
        render: (record, data) => {
          return (
            <Row gutter={[8, 0]}>
              <Col>
                <Button onClick={() => handleView(data._id, data)}>View</Button>
              </Col>
            </Row>
          );
        },
      },
    ],
    []
  );

  const handleCustomerDownload = async () => {
   try {
    const response = await downloadCustomers();
    const href = URL.createObjectURL(response.data);
  
    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `user.csv`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

   } catch (error) {
    // console.log(`Download User Error`);
   }
  }
  return (
    <div className="container ">
      <Card
        className="table-cont"
        title={
          <Col span={10}>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search users"
              className="search-input"
            />
          </Col>
        }
        extra={
          <div style={{ position: 'relative', display: 'flex', gap: '1rem' }}>
            <Button id="download-btn" onClick={handleCustomerDownload}>
              {result.isLoading ? 'Downloading...' : 'Download data'}
            </Button>
            <Popover
              placement="bottomRight"
              title={'Filter '}
              content={() => ''}
              trigger="click"
            >
              {/* <Button className="flex filter-btn">
                <img
                  src={filter}
                  style={{ width: '16px', marginRight: '5px' }}
                  alt="filter"
                  className="pl-3"
                />
                Filter by
              </Button> */}
            </Popover>
          </div>
        }
      >
        <Table
          className="table"
          loading={loading}
          dataSource={users.map((user, index) => ({ ...user, key: index }))} // Assigning unique keys to each row
          columns={columns}
          //pagination={}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default AllUsers;
