import "./notfound.css"
import { useNavigate} from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";


const Notfound = () => {
    const navigate = useNavigate();
  return (
    <div className="container notFound">
      <div className="row ">
        <div className="notfound-cont flex">

            <h1 className="big-text">404</h1>
            <p className="medium-text">Page Not Found</p>

            <span className="small-text">...maybe the page you're looking for does not exist or has been deleted.</span>

            <button className='nf-btn' onClick={() => navigate("/dashboard")}>
             <ArrowLeftOutlined /> 
                Back to home 

            </button>

        </div>
      </div>
    </div>
  )
}

export default Notfound