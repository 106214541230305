import { Button, Card, Popover, Row, Table, Col, Typography, Input } from "antd";
import filter from "../../assets/filter.svg";
import { useMemo } from "react";
//import { Link } from "react-router-dom";
import { getTag } from "../../utilities/sanitizer";
import { formatDateTime } from "../../utilities/date-formatter";
import { SearchOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { CurrentPaymentState } from '@/store/payment';
import { PaymentSection } from "@/enums/users";
import { PaymentType } from "@/types/payments";

type AllPaymentsProps = {
  payments: Array<Partial<PaymentType>>;
  loading: boolean;
  setSection: (data: PaymentSection) => void;
};

const AllPayments = ({ payments, loading, setSection }: AllPaymentsProps) => {
  const [payment, setPayment] = useRecoilState(CurrentPaymentState);
  const handleView = (id: string) => {
    setPayment({
      ...payment,
      _id: id,
    });
    setSection(PaymentSection.PAYMENT_DETAILS);
  };
    const columns = useMemo(
        () => [
              {
                title: "User",
                dataIndex: "owner",
                key: "owner",
                render: (record: any, data: any) => {
                  return (
                    <div>
                      <Typography.Title level={5}>
                        {data.owner?.firstName} {data.owner?.lastName}
                      </Typography.Title>
                      <span>{data.owner?.email}</span>
                    </div>
                  );
                  } 
              },
              {
                title: "Device",
                dataIndex: "product",
                key: "device",
                render: (record: any) => {
                  return <>{record?.name}</>;
                }
              },
              {
                title: "Payment terms",
                dataIndex: "payment_terms",
                key: "paymentTerms"
              },
              {
                title: "Payment type",
                dataIndex: "payment_type",
                key: "paymentType"
              },
              {
                title: "Next payment",
                dataIndex: "createdAt",
                key: "createdAt",
                render: record => formatDateTime(record)
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: record => getTag(record)
              },
              {
                    title: "Actions",
                    dataIndex: "_id",
                    key: "Action",
                    render: (record, data) => (
                    <Row gutter={[8, 0]}>
                        <Col>
                          <Button onClick={() => handleView(data._id)}>View</Button>
                        </Col>
                    </Row>
                    )
              }
        ],
        []
      );
  return (
    <div className='container '>
        <Card
        className="table-cont"
        title={
          <Col span={10}>
                <Input
                  prefix={<SearchOutlined />}
                  placeholder="Search payments"
                  className="search-input"
                />
          </Col>
        }
        extra={
          <div style={{ position: "relative" }}>
            <Popover
              placement="bottomRight"
              title={"Filter "}
              content={() => (
                ""
              )}
              trigger="click"
            >
              <Button className="flex filter-btn">
               <img
                  src={filter}
                  style={{ width: "16px", marginRight: "5px" }}
                  alt="filter"
                  className="pl-3"
                />
                Filter by
                
              </Button>
            </Popover>
          </div>
        }
      >
        <Table
          className="table"
          loading={loading}
          dataSource={payments.map((payment,index) => ({ ...payment, key: index }))}
          columns={columns}
          //pagination={}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  )
}

export default AllPayments