import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import KezaLogo from '../../assets/kezalogo.svg';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '@/api/mutations/auth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BusinessState } from '@/store/business';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { isExpired } from '@/utilities/check-jwt';
import { routes } from '@/config/constants/routes';
import { setToken } from '@/utilities/token-helper';
import { UserState } from '@/store/user';
import { useGetBusinessById } from '@/api/mutations/business';

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { login, result } = useLogin();
  const toast = useToast();
  const [user, setUser] = useRecoilState(UserState);
  const { getBusinessById, ...getBusRes } = useGetBusinessById();
  const [businessState, setBusinessState] = useRecoilState(BusinessState);

  const onFinish = async (values: any) => {
    try {
      const { email, password } = values;
      const res = await login({ email, password });
      setToken(res.data.data.token, res.data.data.refresh_token);
      const busRes = await getBusinessById(res.data.data.business);
      setUser({
        ...user,
        userId: res.data.data.userId,
        businessId: res.data.data.business,
      });
      setBusinessState(busRes.data.data);

      navigate(routes.DASHBOARD);
    } catch (error) {
      toast({
        message: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (!isExpired()) {
      navigate(routes.DASHBOARD);
      return;
    }
  }, []);
  return (
    <div className="container tex">
      <div className="row">
        <div className="col-md-12">
          <div className="login-form-wrapper">
            <div className="d-flex justify-content-center mb-3">
              <img alt="keza" src={KezaLogo} />
            </div>
            <Form onFinish={onFinish} layout={'vertical'} form={form}>
              <Form.Item
                required
                rules={[
                  { required: true, message: 'Please input your email!' },
                ]}
                label="Email Address"
                name={'email'}
              >
                <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                  <Input type="email" placeholder="john.doe@kezaafrica.com" />
                </div>
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: 'Please input your passowrd!' },
                ]}
                required
                label="Password"
                name={'password'}
              >
                <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                  <Input.Password placeholder="password" />
                </div>
              </Form.Item>

              <div >
                <a  style={{color:'black'}} href={routes.FORGOT_PASSWORD}>

                Forgot password?
                </a>
                </div>
              <br />
              <Form.Item>
                <div className="d-flex justify-content-center">
                  <Button
                    htmlType="submit"
                    className="keza-btn-primary"
                    type="primary"
                    loading={result.isLoading}
                  >
                    Sign in to Dashboard
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
