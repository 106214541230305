//import { createContext, useState } from "react";
import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';
import Dashboard from './pages/Dashboard/dashboard';
import Applications from './pages/Applications/Application';
import DashboardHoc from './components/DashHoc/DashboardHoc';
import Notfound from './pages/notfound/notfound';
import Login from './pages/Login/LoginPage';
import Users from './pages/Users/Users';
import Payments from './pages/Payments/Payments';
import Settings from './pages/Settings/Settings';
/*import ApplicationDetail from './pages/ApplicationDetail/ApplicationDetail';
import Orders from './pages/Order/Orders';
import Devices from './pages/Devices/Devices';
import UserDetails from './pages/UserDetails/UserDetails';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import PaymentDetails from './pages/PaymentDetails/PaymentDetails';
import DeviceDetails from './pages/DeviceDetails/DeviceDetails';*/
import { routes } from './config/constants/routes';
import Devices from './pages/Devices/Devices';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<DashboardHoc />}>
        <Route index element={<Dashboard />} />
        <Route path={routes.DASHBOARD} element={<Dashboard />} />
        <Route path={routes.APPLICATIONS} element={<Applications />} />
        <Route path={routes.USERS} element={<Users />} />
        <Route path={routes.PAYMENTS} element={<Payments />} />
        <Route path={routes.SETTINGS} element={<Settings />} />
        <Route path={routes.DEVICES} element={<Devices />} />

        <Route path="settings" element={<Settings />} />
      </Route>
        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={'/forgot-password'} element={<ResetPassword />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}

export default App;
