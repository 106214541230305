export function generateQueryParamURL(url: string, obj: any) {
  const queryParams = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== "") {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(obj[key]);
      queryParams.push(`${encodedKey}=${encodedValue}`);
    }
  }

  const queryString = queryParams.join("&");
  return `${url}${queryString ? "?" : ""}${queryString}`;
}
