import { AppStorage } from './storage';

const appStorage = new AppStorage();

export function getToken() {
  const access_token = appStorage.getValue('access-token');
  const refresh_token = appStorage.getValue('refresh-token');

  return {
    access_token,
    refresh_token,
  };
}
export function setToken(access_token: string, refresh_token?: string) {
  appStorage.setValue('access-token', access_token);
  refresh_token && appStorage.setValue('refresh-token', refresh_token);
}
