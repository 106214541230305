import { Button, Card, Col, Input, Popover, Row, Spin } from "antd";
import filter from "../../assets/filter.svg";
import { SearchOutlined } from "@ant-design/icons";
import { useGetAllDevices } from "@/api/mutations/devices";
import { useEffect, useState } from "react";
import { useToast } from "@/hooks/useToast";
import { getErrorMessage } from "@/utilities/handle-error";
import PhoneCard from "./PhoneCard";
import { useRecoilValue } from "recoil";
import { BusinessState } from "@/store/business";
//import { Link } from "react-router-dom";

const Kezashop = () => {
  const { getAllDevices, result } = useGetAllDevices();
  const [search, setSearch] = useState<string>("");
  const business = useRecoilValue(BusinessState);

  const [devices, setDevices] = useState<any>([]);
  const toast = useToast();
  useEffect(() => {
    const getDevices = async () => {
      try {
        const { data } = await getAllDevices({
          filter: { search, businessId: business._id },
        });
        setDevices(data?.data?.list);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: "error",
        });
      }
    };
    getDevices();
  }, [search]);
  return (
    <div className="container ">
      <Card
        className="table-cont"
        title={
          <Col span={10}>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search for a device"
              className="search-input"
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </Col>
        }
        extra={
          <div style={{ position: "relative" }}>
            <Popover
              placement="bottomRight"
              title={"Filter "}
              content={() => ""}
              trigger="click"
            >
              {/* <Button className="flex filter-btn">
                <img
                  src={filter}
                  style={{ width: "16px", marginRight: "5px" }}
                  alt="filter"
                  className="pl-3"
                />
                Filter by
              </Button> */}
            </Popover>
          </div>
        }
      >
        {result.isLoading ? (
          <Spin />
        ) : (
          <Row style={{marginTop:'3rem'}} gutter={[16, 16]}>
            {devices?.map((item: any, index: any) => {
              return (
                <Col key={index}>
                  <PhoneCard
                    prices={item?.prices}
                    product={item?.product}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Card>
    </div>
  );
};

export default Kezashop;
