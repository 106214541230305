import axios from 'axios';
import { env } from '@/config/constants';
import { getToken } from '@/utilities/token-helper';
import { LoginType } from '@/types/auth';
import { axios as privateAxios } from '@/lib/axios';
import { apiUrl } from '../url';

export const refreshAuthToken = async () => {
  const businessState = localStorage.getItem('BusinessState');
  let businessId = '';
  if (businessState) {
    businessId = JSON.parse(businessState).BusinessState._id;
  }
  return axios.post(
    `${env.API_BASE_URL}/businesses/auth/refresh`,
    { businessId },
    {
      headers: {
        Authorization: `Bearer ${getToken().refresh_token}`,
      },
    }
  );
};

export const login = async (data: LoginType) => {
  return privateAxios.post(apiUrl.login, data);
};
