import { axios } from '@/lib/axios';
import { apiUrl } from '../url';
import { generateQueryParamURL } from '@/utilities/generate-query';

export const getAllUsers = async () => {
  return axios.get(apiUrl.getAllUsers);
};

export const getUserById = async (userId: string) => {
  return axios.get(`${apiUrl.getAllUsers}/${userId}`);
};

export const createUser = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  business: string;
}) => {
  return axios.post('/businesses/create-users', data);
};

export const forgotPassword = async (email: string) => {
  return axios.post('/auth/forgot-password', { email });
};

export const resetPassword = async (data: {
  token: string;
  newPassword: string;
}) => {
  return axios.post('/auth/reset-password-with-token', data);
};

export const changePassword = async (data: {
  oldPassword: string;
  newPassword: string;
}) => {
  return axios.post('user/password/update', data);
};
