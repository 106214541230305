import { useMutation } from 'react-query';
import { login } from '../requests/auth';

export const useLogin = () => {
  const { mutateAsync, ...result } = useMutation(login);
  return {
    login: mutateAsync,
    result,
  };
};
