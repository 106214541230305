import { Col, Row, Spin, Typography } from 'antd';
import { getTag } from '../../utilities/sanitizer';
import id from '../../assets/id.svg';
import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { useToast } from '@/hooks/useToast';
import { CurrentCustomerState } from '@/store/customer';
import { CustomerType } from '@/types/customer';

type UserProfileProps = {
  loading: boolean;
};
const UserProfile = ({ loading }: UserProfileProps) => {
  const [customer, setCustomer] = useRecoilState<CustomerType>(CurrentCustomerState);

  const toast = useToast();
  const copyToClipboard = useCallback(async (text: string) => {
    try {
      if (window.navigator) {
        await window.navigator.clipboard.writeText(text);
        toast({
          message: 'User id copied',
          status: 'success',
        });
      }
    } catch (error) {
      toast({
        message: 'An error occured',
        status: 'error',
      });
    }
  }, []);
  return (
    <div className="details-container">
      <Row gutter={[16, 0]} className="details-cont">
        <Col span={24}>
          <Row gutter={[16, 0]} justify={'space-between'} align={'middle'}>
            <Col span={12}>
              <button
                className="flex download-btn"
                onClick={() => copyToClipboard(String(customer.user._id))}
              >
                <img src={id} alt="download" />
                User ID
              </button>
            </Col>
          </Row>
        </Col>
        {loading ? (
          <Spin />
        ) : (
          <Col span={24} className="info-items flex">
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">First name:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {customer?.user?.firstName}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Last name:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {customer?.user?.lastName}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <strong></strong>
                  <span className="info-span">Phone number:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {customer?.user?.phone_number}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Email address:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {customer?.user?.email}
                </Typography.Text>
              </Col>
            </Row>
            <br />
            <br />
            <br />
            {/* <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Endorser's full name:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row> */}
            {/* <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Endorser's email:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row> */}
            <br />
            <br />
            <br />
            {/* <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Car plate number:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Car model/year:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row> */}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default UserProfile;
