/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaymentType } from '@/types/payments';
import implementPersist from '@/utilities/implement-persist';
import { atom } from 'recoil';

export const CurrentPaymentState = atom<Partial<PaymentType>>({
  key: 'CurrentPaymentState',
  default: {
    _id: '',
  },
});
