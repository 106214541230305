import { Row, Typography, Col, Tabs } from 'antd';
import Profile from './Profile';
import Myteam from './Myteam';
import './settings.css';
import { useGetUserById, useGetUsers } from '@/api/mutations/user';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserState } from '@/store/user';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import ChangePassword from './ChangePassword';

const Settings = () => {
  const [user, setUser] = useRecoilState(UserState);
  const { getUserById, result } = useGetUserById();
  const { getAllUsers, ...getUsersResult } = useGetUsers();
  const [allUsers, setAllUsers] = useState<any>([]);
  const toast = useToast();
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getUserById(user.userId);
        const { data } = await getAllUsers();
        setAllUsers(data?.data);
        setUser({
          ...user,
          ...res.data.data,
        });
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: 'error',
        });
      }
    };

    getUser();
  }, []);
  return (
    <div className="container">
      <Row className="mb-10" justify={'space-between'}>
        <Typography.Title level={4}>Settings</Typography.Title>
      </Row>

      <Col span={24}>
        <Tabs>
          <Tabs.TabPane tab="Profile" key="item-1">
            <Profile loading={result.isLoading} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="My team" key="item-2">
            <Myteam
              loading={getUsersResult.result.isLoading}
              allUsers={allUsers}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Change password" key="item-3">
            <ChangePassword />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </div>
  );
};

export default Settings;
