import { Row, Typography, Col, Tabs } from 'antd';
//import VerifiedUsers from './VerifiedUser';
import AllUsers from './AllUsers';
import { useState, useEffect } from 'react';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { UserSection } from '@/enums/users';
import UserDetails from '../UserDetails/UserDetails';
import { useGetAllCustomers } from '@/api/mutations/customers';
import Card from '../Applications/Card';
import { useGetCustomerStat } from '@/api/mutations/user';

const Users = () => {
  const [users, setUsers] = useState([]);
  const { getAllCustomers, result } = useGetAllCustomers();
  const { getCustomerStats, ...gasResult } = useGetCustomerStat();
  const toast = useToast();
  const [section, setSection] = useState(UserSection.ALL_USERS);
  const [filter, setFilter] = useState<any>();
  const [stats, setStats] = useState({
    totalUsersCount: 15,
    totalApprovedUsers: 0,
    totalPendingUsers: 15,
  });
  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await getAllCustomers(filter);
        const res = await getCustomerStats();
        setStats(res.data.data);
        setUsers(data.data.list);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: 'error',
        });
      }
    };
    getUsers();
  }, [filter]);
  function changeTab(val: string) {
    switch (val) {
      case 'item-2':
        setFilter(true);
        break;
      case 'item-3':
        setFilter(false);
        break;
      // case 'item-4':
      //   setFilter('DECLINED');
      //   break;
      default:
        setFilter(undefined);
    }
  }
  return (
    <div className="container">
      <Row className="mb-10" justify={'space-between'}>
        <Typography.Title level={4}>Users</Typography.Title>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Card
            name="Total users"
            value={stats.totalUsersCount}
            loading={gasResult.result.isLoading}
          />
        </Col>
        <Col span={8}>
          <Card
            name="Approved users"
            value={stats.totalApprovedUsers}
            loading={gasResult.result.isLoading}
          />
        </Col>
        <Col span={8}>
          <Card
            name="Pending users"
            value={stats.totalPendingUsers}
            loading={gasResult.result.isLoading}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Tabs onChange={changeTab}>
          <Tabs.TabPane tab="All users" key="item-1">
            {section === UserSection.ALL_USERS && (
              <AllUsers
                setSection={setSection}
                users={users}
                loading={result.isLoading}
              />
            )}
            {section === UserSection.USER_DETAILS && (
              <UserDetails setSection={setSection} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Verified" key="item-2">
            {section === UserSection.ALL_USERS && (
              <AllUsers
                setSection={setSection}
                users={users}
                loading={result.isLoading}
              />
            )}
            {section === UserSection.USER_DETAILS && (
              <UserDetails setSection={setSection} />
            )}{' '}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Unverified" key="item-3">
            {section === UserSection.ALL_USERS && (
              <AllUsers
                setSection={setSection}
                users={users}
                loading={result.isLoading}
              />
            )}
            {section === UserSection.USER_DETAILS && (
              <UserDetails setSection={setSection} />
            )}{' '}
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </div>
  );
};

export default Users;
