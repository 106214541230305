import { FC } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import overViewIcon from '../../assets/overViewIcon.svg';
import applicationIcon from '../../assets/applicationIcon.svg';
import paymentIcon from '../../assets/paymentIcon.svg';
//import orderIcon from '../../assets/orderIcon.svg';
import userIcon from '../../assets/usersicon.svg';
import productIcon from '../../assets/productIcon.svg';
import settingIcon from '../../assets/settingIcon.svg';
import signout from '../../assets/signout.svg';
import { Col } from 'antd';
import down from '../../assets/down.svg';
import logo from '../../assets/kezalogo.svg';
import { routes } from '@/config/constants/routes';
import { useRecoilValue } from 'recoil';
import { BusinessState } from '@/store/business';

const NavItem: FC<{
  name: string;
  IconPath?: string;
  path?: string;
  onClick?: () => void;
}> = ({ name, IconPath, path, onClick }) => {

  
  return (
    <li className="active" onClick={() => onClick && onClick()}>
      <>
        {IconPath && (
          <img
            className="menu-icon"
            src={IconPath}
            alt={'keza-icon'}
            style={{ height: 18 }}
          />
        )}
        {!path || path.trim() === '' ? (
          <span>{name}</span>
        ) : (
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
            to={path}
            end
          >
            {name}
          </NavLink>
        )}
      </>
    </li>
  );
};

const SideBar = () => {
  const navigate = useNavigate();
  function signOut() {
    window.localStorage.clear();
    navigate('/login');
  }
  const business = useRecoilValue(BusinessState)
  return (
    <div
      className="sb-main"
      // style={menuOpen ? { left: "0vw" } : { left: "-60vw" }}
    >
      <Col className="logo-wrapper flex">
        <div>
          <img
            className="keza-logo"
            src={business?.logoUrl || logo}
            alt="logo"
            style={{ height: 35 }}
          />
        </div>

        <div className="logo-wrap-text">
          <h3>{business?.name}</h3>
          <span>Company profile</span>
        </div>

        {/* <img src={down} alt="down" className="logo-icon-down" /> */}
      </Col>

      <nav className="sb-nav-wrapper">
        <ul>
          <NavItem
            name="Overview"
            path="/dashboard"
            IconPath={overViewIcon}
            onClick={() => navigate(routes.DASHBOARD)}
          />
          <NavItem
            name="Applications"
            path="applications"
            IconPath={applicationIcon}
            onClick={() => navigate(routes.APPLICATIONS)}
          />
          <NavItem
            name="Users"
            path="users"
            IconPath={userIcon}
            onClick={() => navigate(routes.USERS)}
          />
          {/* <NavItem
            name="Orders"
            path="orders"
            IconPath={orderIcon}
            onClick={() => navigate(routes.APPLICATIONS)}
          /> */}
          {/* <NavItem name="Payments" path="payments" IconPath={paymentIcon} /> */}
          <NavItem
            name="Devices"
            path="devices"
            IconPath={productIcon}
            onClick={() => navigate(routes.DEVICES)}
          />
          <NavItem
            name="Settings"
            path="settings"
            IconPath={settingIcon}
            onClick={() => navigate(routes.SETTINGS)}
          />
        </ul>
      </nav>

      <br />

      <nav className="sb-nav-wrapper">
        <ul onClick={signOut}>
          <NavItem name="Sign Out" IconPath={signout} />
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
