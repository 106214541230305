import { notification } from 'antd';

type CustomToastProps = {
  status: 'error' | 'success' | 'warning';
  message: string;
  title?: string;
};
export function useToast() {
  function toast({ message, status, title }: CustomToastProps) {
    notification.open({
      message: title ? title : '',
      description: message,
      style: {
        backgroundColor:
          status === 'error'
            ? 'red'
            : status === 'success'
            ? '#32bb78'
            : status === 'warning'
            ? 'yellow'
            : '',
        color: 'white',
      },
    });
  }
  return toast;
}
