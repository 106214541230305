import { Row, Typography, Col, Tabs } from "antd";
import Mydevices from "./Mydevices";
import Kezashop from "./Kezashop";

const Devices = () => {
  return (
    <div className="container">
      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4}>Devices</Typography.Title>
      </Row>

      <Col span={24}>
        <Tabs>
          {/* <Tabs.TabPane tab="My devices" key="item-1">
            <Mydevices />
          </Tabs.TabPane> */}
          <Tabs.TabPane tab="Keza shop" key="item-2">
            <Kezashop />
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </div>
  );
};

export default Devices;
