import { useGetApplicationAnalytics } from "@/api/mutations/dashboard";
import { useToast } from "@/hooks/useToast";
import { getErrorMessage } from "@/utilities/handle-error";
import { Card, Col, DatePicker, Row, Spin } from "antd";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
//import { RANGE_PRESETS } from "../../utilities/date-formatter";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
Chart.register(...registerables);

const ApplicationDash = () => {
  const [value, setValue] = useState("all");
  const [chartData, setChartData] = useState<any>();
  const { getApplicationAnalytics, result } = useGetApplicationAnalytics();
  const toast = useToast();
  const [analytics, setAnalytics] = useState({
    labels: chartData?.graphData?.map((item: any) => item.month),
    datasets: [
      {
        label: "Applications analytics",
        data: chartData?.graphData?.map((item: any) => item.totalApplications),
        borderColor: "#aa4428",
        backgroundColor: "white",
      },
    ],
  });

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const [dateRange, setDateRange] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({ endDate: undefined, startDate: undefined });

  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    setDateRange({ endDate, startDate });
  };

  useEffect(() => {
    const getPaymentDashAnalytics = async () => {
      try {
        const { data } = await getApplicationAnalytics({
          status: value?.toUpperCase(),
          startdate: dateRange.startDate,
          endDate: dateRange.endDate,
        });
        setChartData(data.data);
        setAnalytics({
          labels: data?.data?.graphData?.map((item: any) => item.month),
          datasets: [
            {
              label: "Applications analytics",
              data: data?.data?.graphData?.map((item: any) => {
                if (value === "all") {
                  return item?.totalApplications;
                } else if (value === "approved") {
                  return item?.totalApproveApplications;
                } else {
                  return item?.totalPendingApplications;
                }
              }),
              borderColor: "#aa4428",
              backgroundColor: "white",
            },
          ],
        });
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: "error",
        });
      }
    };
    getPaymentDashAnalytics();
  }, [value, dateRange]);

  return (
    <Row className="mt-10" gutter={[0, 0]}>
      <Col md={16} sm={24}>
        <Card
          title={
            <Radio.Group onChange={onChange} value={value}>
              <Radio value="all">All</Radio>
              <Radio value="approved">Approved</Radio>
              <Radio value="pending">Pending</Radio>
            </Radio.Group>
          }
          extra={
            <DatePicker.RangePicker
              value={[
                moment(dateRange.startDate || new Date()),
                moment(dateRange.endDate || new Date()),
              ]}
              //ranges={RANGE_PRESETS}
              onChange={handleOnRangeChange}
            />
          }
        ></Card>
      </Col>
      <Col md={8} sm={24}>
        {/* <Card title="Metrics"></Card> */}
      </Col>
      {result.isLoading ? (
        <Spin />
      ) : (
        <div style={{ height: "60vh", width: "70vw" }}>
          <Line data={analytics} />
        </div>
      )}
    </Row>
  );
};

export default ApplicationDash;
