import {
  getApplicationAnalytics,
  getPaymentDashAnallytics,
  getUserAnalytics,
} from "./../requests/dashboard";
import { useMutation } from "react-query";

export const useGetPaymentDashAnallytics = () => {
  const { mutateAsync, ...result } = useMutation(getPaymentDashAnallytics);
  return {
    getPaymentDashAnallytics: mutateAsync,
    result,
  };
};

export const useGetUserAnalytics = () => {
  const { mutateAsync, ...result } = useMutation(getUserAnalytics);
  return {
    getUserAnalytics: mutateAsync,
    result,
  };
};

export const useGetApplicationAnalytics = () => {
  const { mutateAsync, ...result } = useMutation(getApplicationAnalytics);
  return {
    getApplicationAnalytics: mutateAsync,
    result,
  };
};
