import { ApplicationType } from '@/types/applications';
import { UserType } from '@/types/user';
import { atom } from 'recoil';

export const ApplicationState = atom<Partial<ApplicationType>>({
  key: 'ApplicationState',
  default: {
    _id: '',
  },
});
