import {
  Button,
  Card,
  Row,
  Table,
  Col,
  Typography,
  Modal,
  Form,
  FormInstance,
  Input,
  Select,
  Checkbox,
} from 'antd';
import bin from '../../assets/bin.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getTag } from '../../utilities/sanitizer';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import Add from '../../assets/Add.svg';
import { useCreateUser } from '@/api/mutations/user';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { useRecoilValue } from 'recoil';
import { BusinessState } from '@/store/business';
const Myteam = ({ allUsers, loading }: any) => {
  const [open, setOpen] = useState(false);
  const business = useRecoilValue(BusinessState);
  const [form] = Form.useForm();
  const { createUser, result } = useCreateUser();
  const toast = useToast();
  const onFinishFailed = (errorInfo: any) => {};
  const [newMember, setNewMember] = useState<any>();
  const [completeDataSource, setCompleteDataSource] = useState<any[]>([]);

  const showModal = () => {
    setOpen(true);
  };

  useEffect(() => {

    const updatedDataSource = [
      ...allUsers.map((user: any, index: any) => ({
        ...user,
        key: index,
      })),
      ...(newMember ? [{ ...newMember, key: newMember._id }] : []),
    ];

    setCompleteDataSource(updatedDataSource);
  }, [allUsers, newMember]);

  const handleCancel = () => {
    setOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'owner',
        key: 'owner',
        render: (record: any, data: any) => {
          return (
            <div>
              <Typography.Title level={5}>
                {data?.firstName} {data?.lastName}
              </Typography.Title>
              <span>{data?.email}</span>
            </div>
          );
        },
      },
      {
        title: 'Phone number',
        dataIndex: 'phone_number',
        key: 'phone_number',
      },

      {
        title: 'Actions',
        dataIndex: '_id',
        key: 'Action',
        render: (record: any, data: any) => (
          <Row gutter={[8, 0]}>
            <Col>
              <Link to={`#`}>
                <Button>Permissions</Button>
              </Link>
            </Col>
            {/* <Col>
              <img src={message} alt="message" />
            </Col> */}
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img src={bin} alt="delete" />
            </Col>
          </Row>
        ),
      },
    ],
    [allUsers, newMember]
  );

  const onChangeChecked = (checkedValues: CheckboxValueType[]) => {};

  async function onFinish(values: any) {
    try {
      const res = await createUser({ ...values, business: business._id });
      setNewMember(res.data.data);
      handleCancel();
      toast({ message: 'Operation successful', status: 'success' });
    } catch (error) {
      toast({ message: getErrorMessage(error), status: 'error' });
    }
  }
  return (
    <div className="container ">
      <div className="team-header">
        <h3>Team members</h3>
      </div>
      <hr />

      <Card
        className="table-cont"
        title={
          <>
            <button className="p-btn" onClick={showModal}>
              <img src={Add} alt="img" />
              <span> Add new member</span>
            </button>
            <Modal
              open={open}
              title={<h3 className="modal-title">Add a teammate</h3>}
              // onOk={handleOk}
              onCancel={handleCancel}
            >
              <div>
                <div className="profile-cont">
                  <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    form={form}
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    style={{ maxWidth: 960 }}
                  >
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your First name!',
                            },
                          ]}
                        >
                          <Input id="firstName" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Last name!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item
                          label="Email address"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your email!',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Phone number"
                          name="phoneNumber"
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Please input your phone number!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter email" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Please input your passowrd!',
                            },
                          ]}
                          required
                          label="Password"
                          name={'password'}
                        >
                          <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                            <Input.Password placeholder="password" />
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Row justify={'center'} align={'middle'}>
                        <Button
                          htmlType="submit"
                          className="keza-btn-primary"
                          loading={result.isLoading}
                          style={{ backgroundColor: '#AA4428 !important' }}
                        >
                          <span> Create user</span>
                        </Button>
                      </Row>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Modal>
          </>
        }
      >
        <Table
          className="table"
          loading={loading}
          dataSource={completeDataSource}
          columns={columns}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default Myteam;
