import { Col, Row, Typography } from 'antd';
import { getTag } from '../../utilities/sanitizer';
import { DetailsProp } from './Details';

const Device = ({ deviceDetails, loading }: DetailsProp) => {
  return (
    <div className="details-container">
      <Row gutter={[16, 0]} className="details-cont">
        <Col span={24}></Col>
        <Col span={24} className="info-items flex">
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <span className="info-span">Device name:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
                {deviceDetails?.product?.name}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <span className="info-span">Device condition:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
                {deviceDetails?.phonCondition}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <strong></strong>
                <span className="info-span">Capacity:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
                {deviceDetails.phoneStorage}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <span className="info-span">Color:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
                {deviceDetails.phoneColor}
              </Typography.Text>
            </Col>
          </Row>

          {/*                   
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Total amount:</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {""}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Initial deposit:</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {""}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">Monthly repayment:</span>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text className="info-des">
                        {""}
                      </Typography.Text>
                    </Col>
                  </Row>                  */}
        </Col>
      </Row>
    </div>
  );
};

export default Device;
