import { useMutation } from 'react-query';
import { getAllCustomers, getCustomerById } from '../requests/customers';

export const useGetAllCustomers = () => {
  const { mutateAsync, ...result } = useMutation(getAllCustomers);
  return {
    getAllCustomers: mutateAsync,
    result,
  };
};

export const useGetCustomerById = ()=> {
  const { mutateAsync, ...result } = useMutation(getCustomerById);
  return {
    getCustomerById: mutateAsync,
    result,
  };
};