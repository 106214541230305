import { ArrowLeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import Details from "./Details";
import Device from "./Device";
import ApplicationPayments from "./ApplicationPayments";
import { ApplicationSection } from "@/enums/applications";
import { useRecoilState, useRecoilValue } from "recoil";
import { ApplicationState } from "@/store/application";
import { useCallback, useEffect, useState } from "react";
import { useGetApplicationById } from "@/api/mutations/applications";
import { DeviceDteailsType } from "@/types/applications";
import { useToast } from "@/hooks/useToast";
import { getErrorMessage } from "@/utilities/handle-error";
import id from "../../assets/id.svg";
import User from "./User";

const ApplicationDetail = ({
  setSection,
}: {
  setSection: (data: ApplicationSection) => void;
}) => {
  const [applicationState, setApplicationState] =
    useRecoilState(ApplicationState);
  const { getApplicationById, result } = useGetApplicationById();
  const [deviceDetails, setDeviceDetails] = useState<any>();
  const toast = useToast();
  useEffect(() => {
    const getApplication = async () => {
      try {
        const res = await getApplicationById({
          appId: String(applicationState._id),
        });
        setDeviceDetails(res.data.data);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: "error",
        });
      }
    };
    getApplication();
  }, []);
  const copyToClipboard = useCallback(async (text: string) => {
    try {
      if (window.navigator) {
        await window.navigator.clipboard.writeText(text);
        toast({
          message: "Application id copied",
          status: "success",
        });
      }
    } catch (error) {
      toast({
        message: "An error occured",
        status: "error",
      });
    }
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <br />

          <Row>
            <Col span={24}>
              <Card
                title={
                  <>
                    <Row>
                      <Col span={24}>
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            setSection(ApplicationSection.ALL_APPLICATIONS)
                          }
                        >
                          <ArrowLeftOutlined /> Back to All applications
                        </span>
                      </Col>
                    </Row>
                  </>
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <Row
                        gutter={[16, 0]}
                        justify={"space-between"}
                        align={"middle"}
                        style={{
                          margin: "2rem 0",
                        }}
                        onClick={() => copyToClipboard(deviceDetails?._id)}
                      >
                        <Col span={12}>
                          <button className="flex download-btn">
                            <img src={id} alt="download" />
                            Application ID
                          </button>
                        </Col>
                      </Row>
                      <Tabs>
                        <Tabs.TabPane
                          tab="User"
                          key="item-1"
                          className="detail-nav"
                        >
                          <User
                            deviceDetails={deviceDetails}
                            loading={result.isLoading}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab="Details"
                          key="item-2"
                          className="detail-nav"
                        >
                          <Details
                            deviceDetails={deviceDetails}
                            loading={result.isLoading}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab="Device"
                          key="item-3"
                          className="detail-nav"
                        >
                          <Device
                            deviceDetails={deviceDetails}
                            loading={result.isLoading}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab="Payments"
                          key="item-4"
                          className="detail-nav"
                        >
                          <ApplicationPayments
                            deviceDetails={deviceDetails}
                            loading={result.isLoading}
                          />
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetail;
