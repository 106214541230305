import { axios } from '@/lib/axios';
import { apiUrl } from '../url';
import { generateQueryParamURL } from '@/utilities/generate-query';

export const getAllApplications = async (data: {
  page?: number;
  status?: string;
}) => {
  return axios.get(generateQueryParamURL(apiUrl.getApplications, data));
};

export const getApplicationById = async (data: { appId: string }) => {
  return axios.get(`${apiUrl.getApplications}/${data.appId}`);
};

export const getApplicationPayments = async (appId: string) => {
  return axios.get(`${apiUrl.getApplicationPayments}/${appId}/payments`);
};

export const downloadApplicationData = async () => {
  return axios.get('/businesses/admin/applications/download', { responseType: "blob" });
};

export const getApplicationStat = async () => {
  return axios.get('/businesses/admin/applications/cards');
};
