export const routes = {
    DASHBOARD:'/dashboard',
    LOGIN:'/login',
    APPLICATIONS:'/applications',
    PAYMENTS:'/payments',
    USERS:'/users',
    SETTINGS:'/settings',
    DEVICES:'/devices',
    FORGOT_PASSWORD:'/forgot/password',
    RESET_PASSWORD:'/reset/password'
}