//import { useState } from "react";
import { Row, Button, Card, Form, Input, Col, Spin } from 'antd';
import Edit from '../../assets/Edits.svg';
import avatar from '../../assets/logoAvatar.svg';
import { useRef } from 'react';
import type { FormInstance } from 'antd/es/form';
import { useRecoilValue } from 'recoil';
import { UserState } from '@/store/user';
import { BusinessState } from '@/store/business';
//import {showMessage } from "../../helpers";

const Profile = ({ loading }: { loading: boolean }) => {
  const formRef = useRef<FormInstance>(null);
  const [form] = Form.useForm();
  const user = useRecoilValue(UserState)

  const onFinishFailed = (errorInfo: any) => {};
  const business = useRecoilValue(BusinessState)
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Card
            className="card-bord"
            title={
              <Row className="" justify={'space-between'}>
                {/* <Button className="flex filter-btn">
                  Edit profile
                  <img
                    src={Edit}
                    style={{ width: '24px', marginRight: '5px' }}
                    alt="filter"
                    className="pl-3"
                  />
                </Button> */}
              </Row>
            }
          >
            <hr />
            <div className=" myprofile grid">
              <div className="profile-left flex">
                <div className="left-img flex" style={{maxWidth:'4rem'}}>
                  <img width={'100%'} height={'100%'} src={business?.logoUrl} alt="avatar" />
                </div>

                {/* <div className="left-des flex">
                  <div className="des-cont">
                    <button className="change-btns">Upload logo</button>
                  </div>
                  <div className="des-cont">
                    <button className="change-btns">Use default logo</button>
                  </div>
                </div> */}
              </div>
              <div className="profile-right">
                <div className="profile-cont">
                  {loading ? (
                    <Spin />
                  ) : (
                    <Form
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      layout="vertical"
                      ref={formRef}
                      form={form}
                      onFinishFailed={onFinishFailed}
                      //disabled={componentDisabled}
                      style={{ maxWidth: 960 }}
                    >
                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            valuePropName="firstname"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your First name!',
                              },
                            ]}
                          >
                            <Input value={user?.firstName}  />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            valuePropName="lastname"

                            rules={[
                              {
                                required: true,
                                message: 'Please input your Last name!',
                              },
                            ]}
                          >
                            <Input value={user?.lastName}  />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col span={12}>
                          <Form.Item
                            label="Work email"
                            name="Work_email"
                            valuePropName="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your email!',
                              },
                            ]}
                          >
                            <Input value={user?.email} readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Phone number"
                            name="Phone_number"
                            valuePropName="number"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your phone number!',
                              },
                            ]}
                          >
                            <Input value={user?.phone_number} readOnly />
                          </Form.Item>
                        </Col>
                      </Row>

                     
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Profile;
