import { Col, Row } from 'antd';
import Header from '../Header/Header';
import SideBar from '../Sidebar/Sidebar';
import './DashboardHoc.css';
import { Navigate, Outlet } from 'react-router-dom';
import { isExpired } from '@/utilities/check-jwt';

const DashboardHoc = () => {
  //get user details and save to state

  if (isExpired()) {
    return <Navigate to={'/login'} />;
  } else {
    return (
      <div className="ds-main">
        <Row className="ds-main-cont">
          <Col style={{ backgroundColor: 'red' }}>
            <SideBar />
          </Col>
          <Col>
            <Header />
            <Col className="main-cont">
              <Outlet />
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
};

export default DashboardHoc;
