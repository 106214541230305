import { axios } from '@/lib/axios';
import { apiUrl } from '../url';

export const getBusinessByName = async (businessName: string) => {
  return axios.get(`${apiUrl.getBusinessByName}/${businessName}`);
};

export const getBusinessById = async (id: string) => {
  return axios.get(`${apiUrl.getBusinessById}/${id}`);
};
