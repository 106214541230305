import implementPersist from '@/utilities/implement-persist';
import { atom } from 'recoil';

export const UserState = atom<any>({
  key: 'UserState',
  default: {
    userId: '',
    businessId:''
  },
  effects_UNSTABLE: implementPersist('UserState'),

});
