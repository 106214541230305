import { Card, Col, Table } from 'antd';
//import id from '../../assets/id.svg';
import { useMemo } from 'react';
import { getTag } from '../../utilities/sanitizer';
import { formatDateTime } from '../../utilities/date-formatter';
import { PaymentType } from '@/types/payments';
import { numberWithCommas } from '@/utilities/number-formatter';

const UserPayments = ({
  payments,
  loading,
}: {
  payments: Array<PaymentType>;
  loading: boolean;
}) => {
  const columns = useMemo(
    () => [
      {
        title: 'Description',
        dataIndex: 'type',
        key: 'type',
        render: (record: any) => {
          return (
            <p style={{ textTransform: 'capitalize' }}>
              {String(record).toLowerCase()}
            </p>
          );
        },
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (record) => `₦${numberWithCommas(record)}`,
      },
      {
        title: 'Due date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (record) => formatDateTime(record),
      },
      {
        title: 'Channel',
        dataIndex: 'paymentChannel',
        key: 'paymentChannel',
        render: (record) => (
          <p style={{ textTransform: 'capitalize' }}>
            {String(record).toLowerCase()}
          </p>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (record) => getTag(record),
      },
      {
        title: 'Transaction date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (record) => formatDateTime(record),
      },
    ],
    []
  );
  return (
    <div className="container ">
      <Card
        className="table-cont"
        // title={
        //   <Col span={12}>
        //     <button className="flex download-btn">
        //       <img src={id} alt="download" />
        //       User ID
        //     </button>
        //   </Col>
        // }
        extra={
          <Col span={12}>
            Status:{' '}
            {getTag(
              ''
              /*{} ===
                      "DECLINED"
                      ? "rejected"
                      : {""}
                      ? "Approved"
                      : "Pending"*/
            )}
          </Col>
        }
      >
        <Table
          className="table"
          loading={loading}
          dataSource={payments.map((item, index) => ({ ...item, key: index }))}
          columns={columns}
          //pagination={}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default UserPayments;
