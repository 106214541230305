import Meta from "antd/lib/card/Meta";
import hey from "../../assets/hey.svg";
import { Col, Tabs } from "antd";
import PaymentDash from "./PaymentDash";
import ApplicationDash from "./ApplicationDash";
import UserDash from "./UserDash";

const dashboard = () => {
  return (
    <div className="dsb-overview container">
      <Meta
        title={
          <div className="flex">
            {`Welcome back ${""}`}
            <img src={hey} alt="hey" style={{ width: 20 }} />
          </div>
        }
        description="Take a look at your latest activity"
      />
      <br />
      <br />
      <Col span={24}>
        <Tabs>
          <Tabs.TabPane tab="Payments" key="item-1">
            <PaymentDash />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Applications" key="item-2">
            <ApplicationDash />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Users" key="item-3">
            <UserDash />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Orders" key="item-4">
            <OrderDash />
          </Tabs.TabPane> */}
        </Tabs>
      </Col>
    </div>
  );
};

export default dashboard;
