import { Row, Typography, Col, Tabs } from 'antd';
import AllAplications from './AllAplications';
import PendingAplications from './PendingAplications';
import ApprovedAplications from './ApprovedAplications';
import Declinedapplications from './Declinedapplications';
import { useEffect, useState } from 'react';
import {
  useGetAllApplications,
  useGetApplicationStat,
} from '@/api/mutations/applications';
import { ApplicationType } from '@/types/applications';
import ApplicationDetail from '../ApplicationDetail/ApplicationDetail';
import { ApplicationSection } from '@/enums/applications';
import Card from './Card';
import { getApplicationStat } from '@/api/requests/application';

const Application = () => {
  const [applications, setApplications] = useState<Array<ApplicationType>>([]);
  const { getAllApplications, result } = useGetAllApplications();
  const { getApplicationStat, ...gasResult } = useGetApplicationStat();
  const [section, setSection] = useState(ApplicationSection.ALL_APPLICATIONS);
  const [filter, setFilter] = useState<any>();
  const [stats, setStats] = useState({
    approvedApplicationCount: 0,
    pendingApplicatonCount: 0,
    declinedApplicationCount: 0,
    totalApplicationCount: 0,
  });

  useEffect(() => {
    const fetchAllAccounts = async () => {
      try {
        const res = await getAllApplications({ page: 1, status: filter });
        const { data } = await getApplicationStat();
        setStats(data.data);
        setApplications(res.data.data.list);
      } catch (error) {}
    };
    fetchAllAccounts();
  }, [filter]);

  function changeTab(val: string) {
    switch (val) {
      case 'item-2':
        setFilter('PENDING');
        break;
      case 'item-3':
        setFilter('APPROVED');
        break;
      case 'item-4':
        setFilter('DECLINED');
        break;
      default:
        setFilter(undefined);
    }
  }
  return (
    <div className="container">
      <Row className="mb-10" justify={'space-between'}>
        <Typography.Title level={4}>Applications</Typography.Title>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Card
            name="Total applications"
            value={stats.totalApplicationCount}
            loading={gasResult.result.isLoading}
          />
        </Col>
        <Col span={6}>
          <Card
            name="Approved applications"
            value={stats.approvedApplicationCount}
            loading={gasResult.result.isLoading}
          />
        </Col>
        <Col span={6}>
          <Card
            name="Pending applications"
            value={stats.pendingApplicatonCount}
            loading={gasResult.result.isLoading}
          />
        </Col>
        <Col span={6}>
          <Card
            name="Declined applicatins"
            value={stats.declinedApplicationCount}
            loading={gasResult.result.isLoading}
          />
        </Col>
      </Row>

      <Col span={24}>
        <Tabs onChange={changeTab}>
          <Tabs.TabPane tab="All applications" key="item-1">
            {section === ApplicationSection.ALL_APPLICATIONS && (
              <AllAplications
                setSection={setSection}
                loading={result.isLoading}
                applications={applications}
              />
            )}
            {section === ApplicationSection.APPLICATION_DETAILS && (
              <ApplicationDetail setSection={setSection} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Pending" key="item-2">
            {section === ApplicationSection.ALL_APPLICATIONS && (
              <AllAplications
                setSection={setSection}
                loading={result.isLoading}
                applications={applications}
              />
            )}
            {section === ApplicationSection.APPLICATION_DETAILS && (
              <ApplicationDetail setSection={setSection} />
            )}{' '}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approved" key="item-3">
            {section === ApplicationSection.ALL_APPLICATIONS && (
              <AllAplications
                setSection={setSection}
                loading={result.isLoading}
                applications={applications}
              />
            )}
            {section === ApplicationSection.APPLICATION_DETAILS && (
              <ApplicationDetail setSection={setSection} />
            )}{' '}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Declined" key="item-4">
            {section === ApplicationSection.ALL_APPLICATIONS && (
              <AllAplications
                setSection={setSection}
                loading={result.isLoading}
                applications={applications}
              />
            )}
            {section === ApplicationSection.APPLICATION_DETAILS && (
              <ApplicationDetail setSection={setSection} />
            )}{' '}
          </Tabs.TabPane>
        </Tabs>
      </Col>
    </div>
  );
};

export default Application;
