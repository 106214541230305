import { Row, Typography, Col, Tabs } from "antd"
import AllPayments from "./AllPayments"
import PendingPayments from "./PendingPayments"
import DefaultingPayments from "./DefaultingPayments"
import CompletedPayments from "./CompletedPayments"
import { useGetPayments } from "@/api/mutations/payment"
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { PaymentSection } from '@/enums/users';
import { useEffect, useState } from "react"
import PaymentDetails from "../PaymentDetails/PaymentDetails"

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const { getAllPayments, result } = useGetPayments();
  const toast = useToast();
  const [section, setSection] = useState(PaymentSection.ALL_PAYMENTS);

  useEffect(() => {
    const getPayments = async () => {
      try {
        const { data } = await getAllPayments();
        setPayments(data.data);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: 'error',
        });
      }
    };
    getPayments();
  }, []);
  return (
    <div className="container">
      <Row className="mb-10" justify={"space-between"}>
        <Typography.Title level={4}>Payments</Typography.Title>
      </Row>

      <Col span={24}>
              <Tabs>
                <Tabs.TabPane tab="All orders" key="item-1">
                  {section === PaymentSection.ALL_PAYMENTS && (
                    <AllPayments setSection={setSection} payments={payments} loading={result.isLoading} />
                  )}
                  {section === PaymentSection.PAYMENT_DETAILS && (
                    <PaymentDetails  setSection={setSection}/>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Pending" key="item-2">
                  <PendingPayments/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Completed" key="item-3">
                  <CompletedPayments />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Defaulting" key="item-4">
                  <DefaultingPayments/>
                </Tabs.TabPane>
              </Tabs>
      </Col>
    </div>
  )
}

export default Payments