import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import avatar from '../../assets/avatar.svg';

const Header = () => {
  return (
    <Row className="app-header">
      <Row gutter={[16, 0]} className="app-header" justify={'space-between'}>
        <Col span={10}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search by order ID, customer...."
            className="search-input"
          />
        </Col>
        <Col span={6}>
          <Row className="cursor-pointer" justify={'end'} align={'middle'}>
            <Col>
              <div className="avatar-card cursor-pointer">
                <Row align={'middle'}>
                  <img style={{ height: 40 }} src={avatar} alt="user" />
                </Row>
              </div>
            </Col>
            <Col>
              <Row align={'middle'} className="flex">
                <div className="hd-user-name">
                  <h3>{''}</h3>
                  <p>{''}</p>
                </div>
                {/* <DownOutlined /> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default Header;
