import { useMutation } from "react-query"
import { getBusinessById, getBusinessByName } from "../requests/business"

export const useGetBusinessByName = ()=>{
    const {mutateAsync,...result } = useMutation(getBusinessByName)
    return{
        getBusinessByName:mutateAsync,
        result
    }
}
export const useGetBusinessById = ()=>{
    const {mutateAsync,...result } = useMutation(getBusinessById)
    return{
        getBusinessById:mutateAsync,
        result
    }
}