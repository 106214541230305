import { useMutation } from 'react-query';
import { changePassword, createUser, forgotPassword, getAllUsers, getUserById, resetPassword } from '../requests/users';
import { downloadCustomers, getCustomerStats } from '../requests/customers';

export const useGetUsers = () => {
  const { mutateAsync, ...result } = useMutation(getAllUsers);
  return {
    getAllUsers: mutateAsync,
    result,
  };
};

export const useGetUserById = () => {
  const { mutateAsync, ...result } = useMutation(getUserById);
  return {
    getUserById: mutateAsync,
    result,
  };
};

export const useDownloadCustomers = () => {
  const { mutateAsync, ...result } = useMutation(downloadCustomers);
  return {
    downloadCustomers: mutateAsync,
    result,
  };
};
export const useGetCustomerStat = () => {
  const { mutateAsync, ...result } = useMutation(getCustomerStats);
  return {
    getCustomerStats: mutateAsync,
    result,
  };
};

export const useCreateUser = () => {
  const { mutateAsync, ...result } = useMutation(createUser);
  return {
    createUser: mutateAsync,
    result,
  };
};

export const useForgotPassword =()=>{
  const {mutateAsync,...result} = useMutation(forgotPassword);
  return {
    forgotPassword:mutateAsync,
    result
  }
}

export const useResetPassword =()=>{
  const {mutateAsync,...result} = useMutation(resetPassword);
  return {
    resetPassword:mutateAsync,
    result
  }
}

export const useChangePassword =()=>{
  const {mutateAsync,...result} = useMutation(changePassword);
  return {
    changePassword:mutateAsync,
    result
  }
}