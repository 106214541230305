import { useMutation } from 'react-query';
import { getUserPayments, getAllPayments } from '../requests/payment';

export const useGetUserPayments = () => {
  const { mutateAsync, ...result } = useMutation(getUserPayments);
  return {
    getUserPayments: mutateAsync,
    result,
  };
};

export const useGetPayments = () => {
  const { mutateAsync, ...result } = useMutation(getAllPayments);
  return {
    getAllPayments: mutateAsync,
    result,
  };
};
