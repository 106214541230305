import { ArrowLeftOutlined } from "@ant-design/icons";
import {  Card, Col, Row, Tabs } from "antd";
import PaymentHistory from "./PaymentHistory";
import { PaymentType } from '@/types/payments';
import { BusinessState } from '@/store/business';
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { PaymentSection } from "@/enums/users";
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { useGetUserPayments } from '@/api/mutations/payment';
import { useGetUserById } from '@/api/mutations/user';
import { CurrentCustomerState } from "@/store/customer";


const PaymentDetails = ({
  setSection,
}: {
  setSection: (val: PaymentSection) => void;
}) => {
    const [customer, setCustomer] = useRecoilState(CurrentCustomerState);
    const { getUserById, result } = useGetUserById();
    const { getUserPayments, ...getUserPaymentsResult } = useGetUserPayments();
    const [payments, setPayments] = useState<Array<PaymentType>>([]);
    const toast = useToast();
    const business = useRecoilValue(BusinessState);
    useEffect(() => {
      const getUser = async () => {
        try {
          const res = await getUserById(customer._id as string);
          setCustomer(res.data.data);
        } catch (error) {
          toast({
            message: getErrorMessage(error),
            status: 'error',
          });
        }
      };
      const getPayments = async () => {
        try {
          const res = await getUserPayments({
            userId: customer._id as string,
            businessId: business._id,
          });
          setPayments(res.data.data.list);
        } catch (error) {
          toast({
            message: getErrorMessage(error),
            status: 'error',
          });
        }
      };
      getUser();
      getPayments();
    }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <span className="cursor-pointer" onClick={() => setSection(PaymentSection.ALL_PAYMENTS)}>
                <ArrowLeftOutlined /> Back to All payments
              </span>
            </Col>
          </Row>

          <br />
          <Row>
            <Col span={24}>
              <Card
                title={
                  <>
                    <>
                      <Row gutter={[32, 0]}>
                        <Col>
                          
                        </Col>
                        <Col>
                          
                        </Col>
                      </Row>
                    </>
                  </>
                }
                extra={
                  <Row gutter={[20, 0]}>
                    <Col>
                      
                    </Col>
                    <Col>
                      
                    </Col>
                  </Row>
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <Tabs>
                        
                        <Tabs.TabPane tab="Payment history" key="item-1" className="detail-nav">
                          <PaymentHistory 
                          loading={getUserPaymentsResult.result.isLoading}
                          payments={payments}
                          />
                        </Tabs.TabPane>
                    
                      </Tabs>
                      
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default PaymentDetails