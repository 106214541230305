/* eslint-disable @typescript-eslint/no-explicit-any */
import { BusinessType } from '@/types/business';
import implementPersist from '@/utilities/implement-persist';
import { atom } from 'recoil';

export const BusinessState = atom<any>({
  key: 'BusinessState',
  default: {
    _id: '',
    name: '',
    questions: [],
    branding: {
      faviconUrl: '',
      primaryColor: '',
      secondaryColor: '',
      textColor: '',
      mainSectionText: '',
      mainSectionImageUrl: '',
      howToSectionText: [],
      howToSectionImageUrl: '',
    },
    faq: [],
    homepageUrl: '',
  },
  effects_UNSTABLE: implementPersist('BusinessState'),
});