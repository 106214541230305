import { Col, Image, Row, Spin, Typography } from "antd";
import { getTag } from "../../utilities/sanitizer";
import id from "../../assets/id.svg";
import { DeviceDteailsType } from "@/types/applications";
import { formatKey } from "@/utilities/format-key";

export type DetailsProp = {
  loading: boolean;
  deviceDetails?: any;
};

export function PropertyItem({ name, value }: { name: string; value: string }) {
  return (
    <Row className="mb-3" gutter={[16, 0]}>
      <Col>
        <Typography.Text>
          <span className="info-span">{name}</span>
        </Typography.Text>
      </Col>

      <Col>
        <Typography.Text
          style={{ color: `${value ? "" : "red"}` }}
          className="info-des"
        >
          {value ? value : `Not Uploaded`}
        </Typography.Text>
      </Col>
    </Row>
  );
}
const Details = ({ loading, deviceDetails }: DetailsProp) => {
  return (
    <div className="details-container">
      <Row gutter={[16, 0]} className="details-cont">
        <Col span={24}></Col>
        {loading ? (
          <Spin />
        ) : (
          <Col span={24} className="info-items flex">
            {deviceDetails?.identity?.map((item: any, index: any) => {
              if (item.questionKey === "imageUrl") {
                return (
                  <Row key={index} className="mb-3" gutter={[16, 0]}>
                    <Col>
                      <Typography.Text>
                        <span className="info-span">
                          {formatKey(item.questionKey)}
                        </span>
                      </Typography.Text>
                    </Col>

                    <Col>
                      {!item.response ? (
                        <Typography.Text
                          style={{ color: "red" }}
                          className="info-des"
                        >
                          Not Uploaded
                        </Typography.Text>
                      ) : (
                        <Image
                          src={item.response[item.questionKey]}
                          alt="image"
                        />
                      )}
                    </Col>
                  </Row>
                );
              }
              return (
                <div key={index}>
                  <PropertyItem
                    name={formatKey(item.questionKey)}
                    value={
                      !item?.response
                        ? item.response
                        : item.response[item.questionKey]
                    }
                  />
                </div>
              );
            })}
            {deviceDetails?.employment?.map((item: any, index: any) => {
              if (item.questionKey === "ratingPageUrl") {
                return null;
              }
              return (
                <div key={index}>
                  <PropertyItem
                    name={formatKey(item.questionKey)}
                    value={
                      !item?.response
                        ? item.response
                        : item.response[item.questionKey]
                    }
                  />
                </div>
              );
            })}
            {deviceDetails?.endorser?.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <PropertyItem
                    name={formatKey(item.questionKey)}
                    value={
                      !item?.response
                        ? item.response
                        : item.response[item.questionKey]
                    }
                  />
                </div>
              );
            })}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Details;
