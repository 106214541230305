import React from 'react';
import { Button, Form, Input } from 'antd';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { useChangePassword } from '@/api/mutations/user';

export default function ChangePassword() {
  const [form] = Form.useForm();
  const toast = useToast();
  const { changePassword, result } = useChangePassword();

  const onFinish = async (values: any) => {
    try {
      const res = await changePassword({
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
      });
      toast({
        message: res.data.message,
        status: 'success',
      });
    } catch (error) {
      toast({
        message: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="login-form-wrapper">
          <Form onFinish={onFinish} layout={'vertical'} form={form}>
            <Form.Item
              name="oldPassword"
              label="Old password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                <Input.Password placeholder="Enter new password" />
              </div>
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                <Input.Password placeholder="Enter new password" />
              </div>
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The new password that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                <Input.Password placeholder="Confirm password" />
              </div>
            </Form.Item>

            <br />
            <Form.Item>
              <div className="d-flex justify-content-center">
                <Button
                  htmlType="submit"
                  className="keza-btn-primary"
                  type="primary"
                  loading={result.isLoading}
                >
                  Reset password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
