import { Tag } from "antd";

export const sanitizeRole = (role: string) => {
  switch (role) {
    case "SUPER_ADMIN":
      return "Super Administrator";
    case "OPS_MANAGER":
      return "Head of Operations";
    case "OPS_LEVEL2":
      return "Operations Level 2";
    case "OPS_LEVEL1":
      return "Operations Level 1";
    default:
      return "Staff User";
  }
};

export const getTag = (status: string) => {
  if(!status){
    return
  }
  switch (status.toLowerCase()) {
    case "pending":
    case "due":
      return <Tag color="warning">{status.toUpperCase()}</Tag>;
    case "success":
    case "approved":
    case "completed":
    case "passed":
    case "active":
    case "paid":
    case "credit":
    case "verified":
    case "confirmed":
      return <Tag color="success">{status.toUpperCase()}</Tag>;
    case "failed":
    case "debit":
    case "unverified":
    case "rejected":
    case "cancelled":
    case "inactive":
    case "declined":
      return <Tag color="error">{status.toUpperCase()}</Tag>;
    default:
      return <Tag>{status.toUpperCase()}</Tag>;
  }
};

export const sanitizeValues = (values: any) => {
  Object.keys(values).forEach(key => {
    if (typeof values[key] === "undefined") {
      values[key] = null;
    }
  });

  return values;
};
