import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import KezaLogo from '../../assets/kezalogo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLogin } from '@/api/mutations/auth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BusinessState } from '@/store/business';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { isExpired } from '@/utilities/check-jwt';
import { routes } from '@/config/constants/routes';
import { setToken } from '@/utilities/token-helper';
import { UserState } from '@/store/user';
import { useGetBusinessById } from '@/api/mutations/business';
import { useResetPassword } from '@/api/mutations/user';

const ResetPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { resetPassword, result } = useResetPassword();
  const toast = useToast();
  const { getBusinessById, ...getBusRes } = useGetBusinessById();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const onFinish = async (values: any) => {
    try {
      if (!token) {
        throw new Error('Invalid operation');
      }
      await resetPassword({ token, newPassword: values.password });
      toast({ message: 'Operation successfull', status: 'success' });
      navigate(routes.LOGIN);
    } catch (error) {
      toast({
        message: getErrorMessage(error),
        status: 'error',
      });
    }
  };

  return (
    <div className="container tex">
      <div className="row">
        <div className="col-md-12">
          <div className="login-form-wrapper">
            <div className="d-flex justify-content-center mb-3">
              <img alt="keza" src={KezaLogo} />
            </div>
            <Form onFinish={onFinish} layout={'vertical'} form={form}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                  <Input.Password placeholder="Enter new password" />
                </div>
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The new password that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                  <Input.Password placeholder="Confirm password" />
                </div>
              </Form.Item>

              <div>
                <a
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    fontWeight: 500,
                  }}
                  href={routes.LOGIN}
                >
                  Login
                </a>
              </div>
              <br />
              <Form.Item>
                <div className="d-flex justify-content-center">
                  <Button
                    htmlType="submit"
                    className="keza-btn-primary"
                    type="primary"
                    loading={result.isLoading}
                  >
                    Reset password
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
