import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tabs } from 'antd';
import UserProfile from './UserProfile';
import UserPayments from './UserPayments';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useGetUserById } from '@/api/mutations/user';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { useGetUserPayments } from '@/api/mutations/payment';
import { BusinessState } from '@/store/business';
import { useState } from 'react';
import { PaymentType } from '@/types/payments';
import { UserSection } from '@/enums/users';
import { CurrentCustomerState } from '@/store/customer';
import { useGetCustomerById } from '@/api/mutations/customers';

const UserDetails = ({
  setSection,
}: {
  setSection: (val: UserSection) => void;
}) => {
  const [customer, setCutomer] = useRecoilState(CurrentCustomerState);
  const { getCustomerById, result } = useGetCustomerById();
  const { getUserPayments, ...getUserPaymentsResult } = useGetUserPayments();
  const [payments, setPayments] = useState<Array<PaymentType>>([]);
  const toast = useToast();
  const business = useRecoilValue(BusinessState);
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getCustomerById(customer._id as string);
        setCutomer(res.data.data);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: 'error',
        });
      }
    };
    const getPayments = async () => {      
      try {
        const res = await getUserPayments({
          userId: customer._id as string,
          businessId: business._id,
        });        
        // setPayments(res.data.data.list);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: 'error',
        });
      }
    };
    getUser();
    getPayments();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col span={24}>
              <span
                className="cursor-pointer"
                onClick={() => setSection(UserSection.ALL_USERS)}
              >
                <ArrowLeftOutlined /> Back to All users
              </span>
            </Col>
          </Row>

          <br />
          <Row>
            <Col span={24}>
              <Card
                title={
                  <>
                    <>
                      <Row gutter={[32, 0]}>
                        <Col></Col>
                        <Col></Col>
                      </Row>
                    </>
                  </>
                }
                extra={
                  <Row gutter={[20, 0]}>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                }
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <Tabs>
                        <Tabs.TabPane
                          tab="Profile"
                          key="item-1"
                          className="detail-nav"
                        >
                          <UserProfile loading={result.isLoading} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                          tab="Payment history"
                          key="item-2"
                          className="detail-nav"
                        >
                          <UserPayments
                            loading={getUserPaymentsResult.result.isLoading}
                            payments={payments}
                          />
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
