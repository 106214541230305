import { axios } from "@/lib/axios";
import { apiUrl } from "../url";
import { generateQueryParamURL } from "@/utilities/generate-query";

/*export const getPaymentDashAnallytics = async (status:string, startDate:string, endDate:string) => {
    return axios.get(`${apiUrl.getPaymentDashAnallytics}?{status=${""}&startDate=${""}&endDate=${""}}`);
  };*/

export const getPaymentDashAnallytics = async (data: {
  status?: string;
  startdate?: string;
  endDate?: string;
}) => {
  return axios.get(
    generateQueryParamURL(apiUrl.getPaymentDashAnallytics, data)
  );
};
export const getApplicationAnalytics = async (data: {
  status?: string;
  startdate?: string;
  endDate?: string;
}) => {
  return axios.get(
    generateQueryParamURL(apiUrl.getApplicationAnalyitcs, data)
  );
};
export const getUserAnalytics = async (data: {
  isVerified?: any;
  startdate?: string;
  endDate?: string;
}) => {
  return axios.get(
    generateQueryParamURL(apiUrl.getUserAnalytics, data)
  );
};
