import {
    AxiosError,
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
  } from 'axios';
  // import { axios } from ".";
  import { getErrorMessage } from '@/utilities/handle-error';
  import { INVALID_SIGNATURE, JWT_ABSENT, JWT_EXPIRED } from '@/config/constants/jwt';
  import { axios } from '.';
import { refreshAuthToken } from '@/api/requests/auth';
import { getToken, setToken } from '@/utilities/token-helper';
  
  export function rejectErrorInterceptor(error: AxiosError) {
    return Promise.reject(error);
  }
  
  export function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    const token = getToken().access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
  }
  
  export async function refreshTokenInterceptor(error: any) {
    const prevRequest = { ...error?.config };
    // Check if the response is unauthorized (401 or 520) and the request hasn't been retried yet
    if ([401, 520].includes(error?.response?.status) && !prevRequest?.sent) {
      prevRequest.sent = true;
      try {
        const newToken = await refreshAuthToken();
        setToken(newToken?.data?.data.token);
        prevRequest.headers.authorization = `Bearer ${newToken?.data?.data.token}`;
  
        return axios(prevRequest);
      } catch (err) {
        if (isUnauthorized(err)) {
          localStorage.removeItem('keza_web_refresh-token');
          localStorage.removeItem('keza_web_access-token');
          window.location.replace('/login');
        }
        return Promise.reject(err);
      }
    }
  
    return Promise.reject(error);
  }
  
  function isUnauthorized(err:any) {
    const message = getErrorMessage(err);
    return (
      err?.response?.status === 401 &&
      [
        JWT_EXPIRED,
        INVALID_SIGNATURE,
        JWT_ABSENT,
        'Token not valid || expired',
      ].includes(message)
    );
  }