import { Col, Row, Typography } from "antd";
import { getTag } from "../../utilities/sanitizer";
import { DetailsProp } from "./Details";

const User = ({ deviceDetails, loading }: DetailsProp) => {    
  return (
    <div className="details-container">
      <Row gutter={[16, 0]} className="details-cont">
        <Col span={24}></Col>
        <Col span={24} className="info-items flex">
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <span className="info-span">First name:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
                {deviceDetails?.owner?.firstName}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <span className="info-span">Last name:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
              {deviceDetails?.owner?.lastName}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <strong></strong>
                <span className="info-span">Email:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
              {deviceDetails?.owner?.email}
              </Typography.Text>
            </Col>
          </Row>
          <Row className="mb-3" gutter={[16, 0]}>
            <Col>
              <Typography.Text>
                <span className="info-span">Phone number:</span>
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="info-des">
              {deviceDetails?.owner?.phone_number}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default User;
