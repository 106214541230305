import { numberWithCommas } from '@/utilities/number-formatter';
import { Col, Row, Spin, Typography } from 'antd';
import React from 'react';
import { FiUsers } from 'react-icons/fi';

export default function Card({
  name,
  value,
  loading,
}: {
  name: string;
  value: number;
  loading: boolean;
}) {
  const textStyle = {
    fontSize: '14px',
    opacity: '0.6',
  };
  return (
    <div
      style={{
        border: '0.6px solid #1D1D1D4D',
        borderRadius: '8px',
        height: '8rem',
        padding: '1rem',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <FiUsers size={'1rem'} />

        <Typography>
          <Typography.Text style={textStyle}>{name}</Typography.Text>
        </Typography>
      </div>
      <div style={{ margin: '2rem', fontWeight: 'bold', fontSize: '24px' }}>
        {loading ? <Spin /> : <p>{numberWithCommas(value)}</p>}
      </div>
    </div>
  );
}
