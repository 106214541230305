import jwt_decode from 'jwt-decode';
import { getToken } from './token-helper';

export function isExpired() {
  let token = getToken().access_token;
  if (!token || token === 'undefined') {
    return true;
  }
  let decodedToken = jwt_decode(token) as any;
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
}