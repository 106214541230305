export const apiUrl = {
  login: '/businesses/auth/user-login',
  getBusinessByName: '/businesses',
  getBusinessById: '/businesses/admin/profile',
  getAllCustomers: '/businesses/admin/customers',
  getUserPayments: '/businesses/admin',
  getApplications: '/businesses/admin/applications',
  getApplicationPayments: 'businesses/admin/applications',
  getAllPayments: '/businesses/admin/payments',
  getPaymentDashAnallytics: 'businesses/admin/dashboard/payment/analytics',
  getAllUsers: '/businesses/admin/users',
  getApplicationAnalyitcs: '/businesses/admin/dashboard/applications/analytics',
  getUserAnalytics: '/businesses/admin/dashboard/users/analytics',
  getDevices: '/businesses/admin/devices',
  getUserById: '/businesses/admin/users',
};
