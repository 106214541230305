import { Card, Col, DatePicker, Row, Spin } from "antd";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useToast } from "@/hooks/useToast";
import { getErrorMessage } from "@/utilities/handle-error";
import { useGetPaymentDashAnallytics } from "@/api/mutations/dashboard";
//import { RANGE_PRESETS } from "../../utilities/date-formatter";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
Chart.register(...registerables);

const PaymentDash = () => {
  const [chartData, setChartData] = useState<any>();
  const [value, setValue] = useState("all");
  const [analytics, setAnalytics] = useState({
    labels: chartData?.graphData?.map((item: any) => item.month),
    datasets: [
      {
        label: "Payment",
        data: chartData?.graphData?.map((item: any) => {
          if (value === "all") {
            return item?.revenue;
          } else if (value === "paid") {
            return item?.paidRevenue;
          } else {
            return item?.pendingRevenue;
          }
        }),
        borderColor: "#aa4428",
        backgroundColor: "white",
      },
    ],
  });

  const [dateRange, setDateRange] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
  }>({ endDate: undefined, startDate: undefined });
  const { getPaymentDashAnallytics, result } = useGetPaymentDashAnallytics();
  const toast = useToast();

  useEffect(() => {
    const getPaymentDashAnalytics = async () => {
      try {
        const { data } = await getPaymentDashAnallytics({
          status: value?.toUpperCase(),
          startdate: dateRange.startDate,
          endDate: dateRange.endDate,
        });

        // setChartData(data.data);
        setAnalytics({
          labels: data?.data?.graphData?.map((item: any) => item.month),
          datasets: [
            {
              label: "Payment",
              data: data?.data?.graphData?.map((item: any) => {
                if (value === "all") {
                  return item?.revenue;
                } else if (value === "paid") {
                  return item?.paidRevenue;
                } else {
                  return item?.pendingRevenue;
                }
              }),
              borderColor: "#aa4428",
              backgroundColor: "white",
            },
          ],
        });
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: "error",
        });
      }
    };
    getPaymentDashAnalytics();
  }, [value, dateRange]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleOnRangeChange = (dates: any, dateStrings: [string, string]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    setDateRange({ endDate, startDate });
  };

  return (
    <Row className="mt-10" gutter={[0, 0]}>
      <Col md={16} sm={24}>
        <Card
          title={
            <Radio.Group onChange={onChange} value={value}>
              <Radio value="all">All</Radio>
              <Radio value="paid">Paid</Radio>
              <Radio value="inactive">Inactive</Radio>
            </Radio.Group>
          }
          extra={
            <DatePicker.RangePicker
              value={[
                moment(dateRange.startDate || new Date()),
                moment(dateRange.endDate || new Date()),
              ]}
              //ranges={RANGE_PRESETS}
              onChange={handleOnRangeChange}
            />
          }
        ></Card>
      </Col>

      <Col md={8} sm={24}>
        {/* <Card title="Metrics"></Card> */}
      </Col>

      {result.isLoading ? (
        <Spin />
      ) : (
        <div style={{ height: "60vh", width: "70vw" }}>
          <Line data={analytics} />
        </div>
      )}
    </Row>
  );
};

export default PaymentDash;
