import { CustomerType } from '@/types/customer';
import { atom } from 'recoil';

export const CurrentCustomerState = atom<CustomerType>({
  key: 'CurrentCustomerState',
  default: {
    _id: '',
    business:'',
    user:{
      _id:'',
      firstName:'',
      lastName:'',
      phone_number:'',
      email:''
    }
  },
});
