import { useMutation } from 'react-query';
import {
  downloadApplicationData,
  getAllApplications,
  getApplicationById,
  getApplicationPayments,
  getApplicationStat,
} from '../requests/application';

export const useGetAllApplications = () => {
  const { mutateAsync, ...result } = useMutation(getAllApplications);
  return {
    getAllApplications: mutateAsync,
    result,
  };
};
export const useGetApplicationById = () => {
  const { mutateAsync, ...result } = useMutation(getApplicationById);
  return {
    getApplicationById: mutateAsync,
    result,
  };
};

export const useGetApplicationPayments = () => {
  const { mutateAsync, ...result } = useMutation(getApplicationPayments);
  return {
    getApplicationPayments: mutateAsync,
    result,
  };
};

export const useDownloadApplication = ()=>{
  const {mutateAsync, ...result} = useMutation(downloadApplicationData)
  return {
    downloadApplicationData:mutateAsync,
    result
  }
}

export const useGetApplicationStat = ()=>{
  const {mutateAsync, ...result} = useMutation(getApplicationStat)
  return {
    getApplicationStat:mutateAsync,
    result
  }
}