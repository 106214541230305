import { axios } from '@/lib/axios';
import { apiUrl } from '../url';
import { generateQueryParamURL } from '@/utilities/generate-query';

export const getAllCustomers = async (isVerified: { isVerified?: string }) => {
  // return axios.get(`${apiUrl.getAllUsers}/`);
  return axios.get(generateQueryParamURL(apiUrl.getAllCustomers, { isVerified }));
};

export const getCustomerById = async (userId: string) => {
  return axios.get(`${apiUrl.getAllCustomers}/${userId}`);
};

export const downloadCustomers = async () => {
  return axios.get(`/businesses/admin/customers/download`, { responseType: "blob" });
};

export const getCustomerStats = async ()=>{
  return axios.get('/businesses/admin/customers/cards')
}