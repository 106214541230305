import { Button, Card, Popover, Row, Table, Col, Input } from 'antd';
import filter from '../../assets/filter.svg';
import { useMemo } from 'react';
import { formatDateTime } from '../../utilities/date-formatter';
import { Link } from 'react-router-dom';
import { getTag } from '../../utilities/sanitizer';
import { SearchOutlined } from '@ant-design/icons';
import { ApplicationType } from '@/types/applications';
import { ApplicationSection } from '@/enums/applications';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '@/store/application';
import { useDownloadApplication } from '@/api/mutations/applications';

const AllAplications = ({
  applications,
  loading,
  setSection,
}: {
  applications: Array<ApplicationType>;
  loading: boolean;
  setSection: (data: ApplicationSection) => void;
}) => {
  const [applicationState, setApplicationState] =
    useRecoilState(ApplicationState);
  const { downloadApplicationData, result } = useDownloadApplication();

  const handleApplicationDownload = async () => {
    try {
     const response = await downloadApplicationData();
     const href = URL.createObjectURL(response.data);
   
     // create "a" HTML element with href to file & click
     const link = document.createElement("a");
     link.href = href;
     link.setAttribute(
       "download",
       `application.csv`
     ); //or any other extension
     document.body.appendChild(link);
     link.click();
 
     // clean up "a" element & remove ObjectURL
     document.body.removeChild(link);
     URL.revokeObjectURL(href);
 
    } catch (error) {
    //  console.log(`Download Application Error`);
    }
   }

  const columns = useMemo(
    () => [
      {
        title: 'Device',
        dataIndex: 'product',
        key: 'product',
        render: (record: any, data: any) => {
          return <>{record?.name}</>;
        },
      },
      {
        title: 'Details',
        dataIndex: 'phoneStorage',
        key: 'phoneStorage',
        render: (record: any, data: any) => {
          return (
            <>
              {record}, {data?.phoneColor}
            </>
          );
        },
      },

      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (record) => {
          return formatDateTime(record);
        },
      },

      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (record) => getTag(record),
      },
      {
        title: 'Actions',
        dataIndex: '_id',
        key: 'Action',
        render: (record, data) => (
          <Row gutter={[8, 0]}>
            <Col>
              <Button
                onClick={() => {
                  setApplicationState({ ...applicationState, _id: record });
                  setSection(ApplicationSection.APPLICATION_DETAILS);
                }}
              >
                View
              </Button>
            </Col>
            {/* {data.status !== 'DELETED' && (
              <Col>
                <Button>Delete</Button>
              </Col>
            )} */}
          </Row>
        ),
      },
    ],
    []
  );
  return (
    <div className="container ">
      <Card
        className="table-cont"
        title={
          <Col span={10}>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search applications"
              className="search-input"
            />
          </Col>
        }
        extra={
          <div style={{ position: 'relative', display: 'flex', gap: '1rem' }}>
            <Button id="download-btn" onClick={handleApplicationDownload}>
              {result.isLoading ? 'Downloading...' : 'Download data'}
            </Button>
            <Popover
              placement="bottomRight"
              title={'Filter '}
              content={() => ''}
              trigger="click"
            >
              {/* <Button className="flex filter-btn">
                <img
                  src={filter}
                  style={{ width: '16px', marginRight: '5px' }}
                  alt="filter"
                  className="pl-3"
                />
                Filter by
              </Button> */}
            </Popover>
          </div>
        }
      >
        <Table
          className="table"
          loading={loading}
          dataSource={applications.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns}
          //pagination={}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};

export default AllAplications;
