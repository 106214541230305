export type KeyType = string;

export class AppStorage {
  private prefix: string | 'keza_business_';
  constructor(prefix?: string) {
    this.prefix = prefix || 'keza_business_';
  }

  private getItemKey(key: KeyType) {
    return `${this.prefix}${key}`;
  }

  public getValue(key: KeyType) {
    const itemStr = window.localStorage.getItem(this.getItemKey(key));
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    return item;
  }

  public setValue(key: KeyType, value: unknown) {
    window.localStorage.setItem(this.getItemKey(key), JSON.stringify(value));
  }

  public clearValue(key: KeyType): void {
    window.localStorage.removeItem(this.getItemKey(key));
  }

  public reset() {
    window.localStorage.clear();
  }
}
