import { axios } from '@/lib/axios';
import { apiUrl } from '../url';

export const getUserPayments = (data:{userId: string, businessId: string}) => {
  return axios.get(
    `${apiUrl.getUserPayments}/${data.businessId}/users/${data.userId}/payments`
  );
};

export const getAllPayments = async () => {
  return axios.get(apiUrl.getAllPayments);
};