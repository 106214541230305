import { Col, Row, Spin, Typography } from 'antd';
import { getTag } from '../../utilities/sanitizer';
import id from '../../assets/id.svg';
import { useEffect, useState } from 'react';
import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';
import { useGetApplicationPayments } from '@/api/mutations/applications';
import { DetailsProp } from './Details';
import { numberWithCommas } from '@/utilities/number-formatter';

const ApplicationPayments = ({ deviceDetails, loading }: DetailsProp) => {
  const toast = useToast();
  const [payments, setPayments] = useState<any>();
  const { getApplicationPayments, result } = useGetApplicationPayments();
  useEffect(() => {
    const fetchApplicationPayment = async () => {
      try {
        const res = await getApplicationPayments(deviceDetails._id);
        setPayments(res.data.data);
      } catch (error) {
        toast({
          message: getErrorMessage(error),
          status: 'error',
        });
      }
    };

    fetchApplicationPayment();
  }, []);

  return (
    <div className="details-container">
      <Row gutter={[16, 0]} className="details-cont">
        <Col span={24}></Col>

        {result.isLoading ? (
          <Spin />
        ) : (
          <Col span={24} className="info-items flex">
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Payment option:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {`Pay ${String(payments?.paymentOption)?.toLowerCase()}`}
                </Typography.Text>
              </Col>
            </Row>
            {/* <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Payment terms:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row> */}
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <strong></strong>
                  <span className="info-span">Downpayment:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {`₦ ${numberWithCommas(payments?.downpayment)}`}
                </Typography.Text>
              </Col>
            </Row>
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Total amount:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {`₦ ${numberWithCommas(payments?.totalAmountToPay)}`}
                </Typography.Text>
              </Col>
            </Row>
            {/* <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Initial deposit:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row> */}
            <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Monthly repayment:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">
                  {' '}
                  {`₦ ${numberWithCommas(payments?.monthlyPayment)}`}
                </Typography.Text>
              </Col>
            </Row>
            {/* <Row className="mb-3" gutter={[16, 0]}>
              <Col>
                <Typography.Text>
                  <span className="info-span">Total expected amount:</span>
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="info-des">{''}</Typography.Text>
              </Col>
            </Row> */}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ApplicationPayments;
