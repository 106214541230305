import { Button, Form, Input } from 'antd';

import { useToast } from '@/hooks/useToast';
import { getErrorMessage } from '@/utilities/handle-error';

import { useForgotPassword } from '@/api/mutations/user';

export default function ForgotPassword() {
  const { forgotPassword, result } = useForgotPassword();
  const [form] = Form.useForm();
  const toast = useToast();

  async function onFinish(values: any) {
    try {
      const res = await forgotPassword(values.email);
      toast({
        message: 'Kindly check your email for reset link',
        status: 'success',
      });
    } catch (error) {
      toast({
        message: getErrorMessage(error),
        status: 'error',
      });
    }
  };
  
  return (
    <div className="login-form-wrapper">
      <p style={{ fontWeight: 'bold', fontSize: 20 }}>
        Please enter your email address
      </p>
      <Form onFinish={onFinish} layout={'vertical'} form={form}>
        <Form.Item
          required
          rules={[{ required: true, message: 'Please input your email!' }]}
          label="Email Address"
          name={'email'}
        >
          <div style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
            <Input
              type="email"
              name="email"
              placeholder="Enter email address"
            />
          </div>
        </Form.Item>

        <br />
        <Form.Item>
          <div className="d-flex justify-content-center">
            <Button
              htmlType="submit"
              className="keza-btn-primary"
              type="primary"
              loading={result.isLoading}
            >
              Forgot password
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}
